import {
  Box,
  Container,
  DialogContentText,
  Grid,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppointmentAdsCard from "../../components/AppointmentAdsCard";
import { AuthModal } from "../../components/AuthModal";
import { Footer } from "../../components/Footer";
import { useAppointment } from "../../hooks/useAppointment";
import { useAuthenticatedUser } from "../../store/user";
import CheckoutLayout from "../Checkout/CheckoutLayout";
import { HomeNavbar } from "../Home/components/HomeNavbar";
import { ListServicePackage } from "./components/ListServicePackages";
import { ServicePackageAccount } from "./components/ServicePackageAccount";

export default function AppointmentServicePackage() {
  const theme = useTheme();
  const {
    clinic,
    serviceGroup,
    appointmentStep,
    servicePackage,
    handleChangeAppointmentServiceGroup,
    handleChangeAppointmentServicePackage
  } = useAppointment();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { data: user, isLoading } = useAuthenticatedUser();

  const handleLogin = () => {
    navigate(`/login?redirect=/agendamento-combo`, {
      state: {
        login: true,
      },
      preventScrollReset: true,
    });
  };

  const handleRegister = () => {
    navigate(`/cadastro?redirect=/agendamento-combo`, {
      state: {
        register: true,
      },
      preventScrollReset: true,
    });
  };

  useEffect(() => {
    if (!serviceGroup) {
      navigate("/");
    }
  }, [serviceGroup, navigate]);

  return (
    <Box
      minHeight="100vh"
      id="scroll-container"
      position="relative"
      sx={{
        pb: 2,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <HomeNavbar dark={true} />
      <LinearProgress color="secondary" variant="determinate" value={12.5} />
      <Box
        minHeight="100vh"
        position="relative"
        sx={{
          pb: 4,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            minHeight: matchesSM ? "calc(100vh - 200px)" : "calc(90vh - 64px)",
          }}
        >
          <Grid container mt={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AppointmentAdsCard
                title="Encontre o combo que melhor atenda às suas necessidades e
              preferências"
              />
            </Grid>
          </Grid>

          <ListServicePackage />

          {user &&
            serviceGroup &&
            clinic &&
            servicePackage &&
            appointmentStep > 0 && <ServicePackageAccount />}
        </Container>
      </Box>
      <Footer />

      <CheckoutLayout />

      <AuthModal
        open={!user && !isLoading}
        title="Você está agendando um combo"
        onClickLogin={() => handleLogin()}
        onClickRegister={() => handleRegister()}
        onClose={() => handleChangeAppointmentServiceGroup(null)}
      >
        <DialogContentText
          sx={{
            fontWeight: 600,
          }}
        >
          Informações:
        </DialogContentText>
        <ul>
          <li>
            <DialogContentText>
              Combo: {servicePackage?.titulo}
            </DialogContentText>
          </li>
          <li>
            <DialogContentText>Clínica: {clinic?.nome}</DialogContentText>
          </li>
        </ul>
      </AuthModal>
    </Box>
  );
}
