import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  debounce,
  Grid,
  Stack,
  StepIcon,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { CaretDown, Info, X } from "phosphor-react";
import { useState, useEffect} from "react";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";
import { HealthPlanModal } from "../../../../components/HealthPlanModal";
import { useAppointment } from "../../../../hooks/useAppointment";
import { useClinic } from "../../../../store/clinic";
import { useCategoryHealthPlanItems } from "../../../../store/healthPlan";
import { useServiceGroups } from "../../../../store/serviceGroup";
import { useServiceItem } from "../../../../store/serviceItem";
import { useAppointmentWebSetup } from "../../../../store/setup";
import {
  IAssociatedItem,
  IClinic,
  IServiceGroup,
  IServiceItem,
} from "../../../../types";
import { formatClinicAddress, formatCurrency } from "../../../../utils/masks";
import { singularizeWithArticle } from "../../../../utils/roles";
import { GroupHeader, GroupItems } from "../../../Home";
import { useLocation } from 'react-router-dom';

export const AppointmentStepOne = () => {
  const theme = useTheme();
  const {
    clinic,
    serviceGroup,
    serviceItem,
    handleChangeAppointmentServiceGroup,
    handleChangeAppointmentClinic,
    handleChangeAppointmentServiceItem,
    handleChangeServiceItemHealtPlan,
    handleChangeAppointmentStep,
    handleChangeAppointmentType,
    handleChangeHealthPlan,
    handleChangeCategoryHealthPlan,
    categoryHealthPlan,
    appointmentStep,
    appointmentType,
    healthPlan,
    serviceItemHealtPlan,
    handleChangeHasRedirectedByHome,
    hasRedirectedByHome,
  } = useAppointment();
  const { data: setup } = useAppointmentWebSetup();
  const location = useLocation();

  const navigate = useNavigate();

  const [isOpenAppointmentTypeModal, setIsOpenAppointmentTypeModal] =
    useState(false);
  const [serviceGroupSelected, setServiceGroupSelected] =
    useState<IServiceGroup | null>(serviceGroup);
  const [clinicSelected, setClinicSelected] = useState<IClinic | null>(clinic);
  const [serviceItemSelected, setServiceItemSelected] =
    useState<IServiceItem | null>(serviceItem);
  const [serviceItemHealtPlanSelected, setServiceItemHealthPlanSelected] =
    useState<IAssociatedItem | null>(serviceItemHealtPlan);

  const [searchTerm, setSearchTerm] = useState("");

  const { data: serviceGroups, isLoading: isLoadingServiceGroups } =
    useServiceGroups();

  const { data: clinics, isLoading: isLoadingClinics } = useClinic();

  const { data: serviceItems, isLoading: isLoadingServiceItems } =
    useServiceItem(
      {
        clinicId: clinicSelected ? clinicSelected.idclinica : "",
        searchTerm: searchTerm,
        serviceGroupId: serviceGroupSelected
          ? serviceGroupSelected.idgruposervico
          : "",
      },
      {
        enabled:
          appointmentType === "particular" &&
          !!serviceGroupSelected &&
          !!clinicSelected,
      }
    );

  const {
    data: serviceItemsHealthPlan,
    isLoading: isLoadingServiceItemsHealthPlan,
  } = useCategoryHealthPlanItems(
    {
      healthPlanCategoryId: categoryHealthPlan ? categoryHealthPlan.id : "",
      clinicId: clinicSelected ? clinicSelected.idclinica : "",
      searchTerm: searchTerm,
      serviceGroupId: serviceGroupSelected
        ? serviceGroupSelected.idgruposervico
        : "",
    },
    {
      enabled:
        appointmentType === "convenio" &&
        !!serviceGroupSelected &&
        !!clinicSelected &&
        !!categoryHealthPlan &&
        !!healthPlan,
    }
  );

  const goToAppointmentNextStep = () => {
    if (!serviceGroupSelected) {
      return;
    }

    if (!clinicSelected) {
      return;
    }

    handleChangeAppointmentServiceGroup(serviceGroupSelected);
    handleChangeAppointmentClinic(clinicSelected);

    if (setup && setup.modules.combo) {
      // verica se é combo e direciona para a tela de cobos
      if (serviceGroupSelected.nome.toLowerCase() === "combos") {
        return navigate(`/agendamento-combo`);
      }
    }

    if (setup && setup.modules.convenio && appointmentType === "convenio") {
      if (!serviceItemHealtPlanSelected) {
        return;
      }
      handleChangeAppointmentServiceItem(
        serviceItemHealtPlanSelected.itemservico
      );
      handleChangeServiceItemHealtPlan(serviceItemHealtPlanSelected);
    } else {
      if (!serviceItemSelected) {
        return;
      }
      handleChangeAppointmentServiceItem(serviceItemSelected);
    }

    handleChangeAppointmentStep(1);

    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 200);
  };

  const options = serviceItems ? serviceItems : [];

  const optionsHealthPlan = serviceItemsHealthPlan
    ? serviceItemsHealthPlan
    : [];

  const onChangeSearchTermDebounced = debounce((query: string) => {
    setSearchTerm(query);
  }, 300);

  useEffect(() => { 
    const params = new URLSearchParams(location.search);
    const especialidade = params.get('especialidade');
    const especialidade_id = params.get('especialide_id');
    const clinica = params.get('clinica');
    const clinica_id = params.get('clinica_id');
    const grupo = params.get('grupo');
    const grupo_id = params.get('grupo_id');

    if(grupo && serviceGroups){
      setServiceGroupSelected(serviceGroups.find((e) => e.idgruposervico  === grupo_id) || null)
      ReactGA.event({
        category: "agendamento-web",
        action: "start",
        label: "categoria", //1
      });
    }

    if(clinica_id && clinics){
      setClinicSelected(clinics.find(e => e.idclinica === clinica_id) || null) 
      ReactGA.event({
        category: "agendamento-web",
        action: "start",
        label: "clinica", //2
      });
    }

    if(especialidade_id && options && !serviceItemSelected ) {
      setServiceItemSelected(options.find(e => e?.iditemservico === especialidade_id) || null)
      ReactGA.event({
        category: "agendamento-web",
        action: "start",
        label: "categoria-servico", //3
      });
    }
  },[location.search, serviceGroups, clinics, options])

  

  return (
    <Grid
      container
      mt={2}
      spacing={2}
      justifyContent="center"
      display="flex"
      component="section"
      id="tipo"
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          component="form"
          display="flex"
          noValidate
          autoComplete="off"
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
            display="flex"
          >
            <Stack direction="row" spacing={1} mt={1} alignItems="center">
              <StepIcon icon="1" active={appointmentStep === 0} />
              <Typography
                component="h1"
                variant="h6"
                mb={2}
                fontWeight={600}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontSize: 18,
                }}
              >
                Escolha o tipo de agendamento
              </Typography>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} xs={12}>
                <Typography
                  component="h1"
                  variant="h6"
                  fontWeight={600}
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                    fontSize: 16,
                    mb: 1,
                  }}
                >
                  Selecione um serviço
                </Typography>
                <Autocomplete
                  id="service-group"
                  options={serviceGroups ? serviceGroups : []}
                  getOptionLabel={(value) => value.nome.toUpperCase()}
                  loading={isLoadingServiceGroups}
                  isOptionEqualToValue={(option, value) =>
                    option.idgruposervico === value.idgruposervico
                  }
                  onChange={(event, newValue) => {
                    if (newValue && newValue.nome.toLowerCase() !== "combos") {
                      if (setup && setup.modules.convenio) {
                        setIsOpenAppointmentTypeModal(true);
                      }
                    }

                    setServiceGroupSelected(newValue);
                    setClinicSelected(null);
                    setServiceItemSelected(null);
                    handleChangeAppointmentServiceGroup(null);
                    handleChangeAppointmentClinic(null);
                    handleChangeAppointmentServiceItem(null);
                    handleChangeAppointmentType("particular");
                    handleChangeHealthPlan(null);
                    handleChangeCategoryHealthPlan(null);

                    ReactGA.event({
                      category: "agendamento-web",
                      action: "start",
                      label: "categoria", //1
                    });
                  }}
                  popupIcon={
                    <CaretDown size={18} color="white" weight="fill" />
                  }
                  clearIcon={<X size={18} color="white" weight="fill" />}
                  value={serviceGroupSelected || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Selecione um serviço"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#FFF",
                          backgroundColor: theme.palette.primary.main,
                          "&.MuiButtonBase-root": {
                            color: "#FFF !important",
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "rgba(131, 131, 131, 0.1)",
                            color: "#353535 !important",
                            borderColor: "transparent",
                          },
                          "&.Mui-disabled fieldset": {
                            borderColor: "transparent",
                          },
                        },
                      }}
                      size="medium"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Typography
                  component="h1"
                  variant="h6"
                  fontWeight={600}
                  sx={{
                    color: (theme) =>
                      !serviceGroupSelected
                        ? theme.palette.neutral.main
                        : theme.palette.text.primary,
                    fontSize: 16,
                    mb: 1,
                  }}
                >
                  Selecione uma clínica
                </Typography>
                <Autocomplete
                  id="clinic"
                  options={clinics ? clinics : []}
                  getOptionLabel={(value) => value.nome.toUpperCase()}
                  disabled={!serviceGroupSelected}
                  loading={isLoadingClinics}
                  isOptionEqualToValue={(option, value) =>
                    option.idclinica === value.idclinica
                  }
                  onChange={(event, newValue) => {
                    setClinicSelected(newValue);
                    setServiceItemSelected(null);
                    handleChangeAppointmentClinic(null);
                    handleChangeAppointmentServiceItem(null);

                    ReactGA.event({
                      category: "agendamento-web",
                      action: "start",
                      label: "clinica", //2
                    });
                  }}
                  popupIcon={
                    <CaretDown size={18} color="white" weight="fill" />
                  }
                  clearIcon={<X size={18} color="white" weight="fill" />}
                  value={clinicSelected || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Selecione uma clínica"
                      variant="outlined"
                      size="medium"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#FFF",
                          backgroundColor: theme.palette.primary.main,
                          "&.MuiButtonBase-root": {
                            color: "#FFF !important",
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "rgba(131, 131, 131, 0.1)",
                            color: "#353535 !important",
                            borderColor: "transparent",
                          },
                          "&.Mui-disabled fieldset": {
                            borderColor: "transparent",
                          },
                        },
                      }}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {appointmentType === "particular" && (
                <>
                  {!serviceGroupSelected ? (
                    <Grid item lg={12} md={12} xs={12}>
                      <Typography
                        component="h1"
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          color: (theme) => theme.palette.neutral.main,
                          fontSize: 16,
                          mb: 1,
                        }}
                      >
                        Selecione uma especialidade
                      </Typography>
                      <Autocomplete
                        id="service-item"
                        //   options={serviceItems ? serviceItems : []}
                        options={options.sort(
                          (a, b) =>
                            -b.categoriaservico.nome.localeCompare(
                              a.categoriaservico.nome
                            )
                        )}
                        getOptionLabel={(value) => value.nome}
                        groupBy={(option) => option.categoriaservico.nome}
                        loading={isLoadingServiceItems}
                        disabled={true}
                        onChange={(event, newValue) => {}}
                        popupIcon={
                          <CaretDown size={18} color="white" weight="fill" />
                        }
                        clearIcon={<X size={18} color="white" weight="fill" />}
                        value={serviceItemSelected || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Selecione uma especialidade"
                            variant="outlined"
                            size="medium"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                color: "#FFF",
                                backgroundColor: theme.palette.primary.main,
                                "&.MuiButtonBase-root": {
                                  color: "#FFF !important",
                                },
                                "&.Mui-disabled": {
                                  backgroundColor: "rgba(131, 131, 131, 0.1)",
                                  color: "#353535 !important",
                                  borderColor: "transparent",
                                },
                                "&.Mui-disabled fieldset": {
                                  borderColor: "transparent",
                                },
                              },
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    serviceGroupSelected &&
                    serviceGroupSelected.nome.toLowerCase() !== "combos" && (
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography
                          component="h1"
                          variant="h6"
                          sx={{
                            color: (theme) =>
                              !clinicSelected || !serviceGroupSelected
                                ? theme.palette.neutral.main
                                : theme.palette.text.primary,
                            fontSize: 16,
                            fontWeight: 600,
                            mb: 1,
                          }}
                        >
                          {serviceGroupSelected.nome.toLowerCase() === "exames"
                            ? "Selecione um exame"
                            : "Selecione uma especialidade"}
                        </Typography>
                        <Autocomplete
                          id="service-item"
                          options={
                            serviceGroupSelected.nome.toLowerCase() !==
                            "consultas"
                              ? options.sort(
                                  (a, b) =>
                                    -b.categoriaservico.nome.localeCompare(
                                      a.categoriaservico.nome
                                    )
                                )
                              : options
                          }
                          getOptionLabel={(value) => value.nome}
                          groupBy={
                            serviceGroupSelected.nome.toLowerCase() !==
                            "consultas"
                              ? (option) => option.categoriaservico.nome
                              : undefined
                          }
                          loading={isLoadingServiceItems}
                          disabled={!clinicSelected || !serviceGroupSelected}
                          onInputChange={(e, value) =>
                            onChangeSearchTermDebounced(value)
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.iditemservico === value.iditemservico
                          }
                          filterOptions={(options) => options}
                          noOptionsText="Nesta unidade não há disponibilidade, verifique em outra unidade ou ligue para a central"
                          blurOnSelect
                          onChange={(event, newValue) => {
                            setServiceItemSelected(newValue);
                            handleChangeAppointmentServiceItem(null);

                            ReactGA.event({
                              category: "agendamento-web",
                              action: "start",
                              label: "categoria-servico", //3
                            });
                          }}
                          renderGroup={(params) => (
                            <li key={params.key}>
                              <GroupHeader>{params.group}</GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                            </li>
                          )}
                          popupIcon={
                            <CaretDown size={18} color="white" weight="fill" />
                          }
                          clearIcon={
                            <X size={18} color="white" weight="fill" />
                          }
                          value={serviceItemSelected || null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={
                                serviceGroupSelected.nome.toLowerCase() ===
                                "exames"
                                  ? "Selecione um exame"
                                  : "Selecione uma especialidade"
                              }
                              variant="outlined"
                              size="medium"
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  color: "#FFF",
                                  backgroundColor: theme.palette.primary.main,
                                  "&.MuiButtonBase-root": {
                                    color: "#FFF !important",
                                  },
                                  "&.Mui-disabled": {
                                    backgroundColor: "rgba(131, 131, 131, 0.1)",
                                    color: "#353535 !important",
                                    borderColor: "transparent",
                                  },
                                  "&.Mui-disabled fieldset": {
                                    borderColor: "transparent",
                                  },
                                },
                              }}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    )
                  )}
                </>
              )}
              {setup &&
                setup.modules.convenio &&
                appointmentType === "convenio" && (
                  <>
                    {!serviceGroupSelected ? (
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography
                          component="h1"
                          variant="h6"
                          fontWeight={600}
                          sx={{
                            color: (theme) => theme.palette.neutral.main,
                            fontSize: 16,
                            mb: 1,
                          }}
                        >
                          Selecione uma especialidade
                        </Typography>
                        <Autocomplete
                          id="service-item-helth-plan"
                          //   options={serviceItems ? serviceItems : []}
                          options={optionsHealthPlan.sort(
                            (a, b) =>
                              -b.itemservico.categoriaservico.nome.localeCompare(
                                a.itemservico.categoriaservico.nome
                              )
                          )}
                          getOptionLabel={(value) => value.itemservico.nome}
                          groupBy={(option) =>
                            option.itemservico.categoriaservico.nome
                          }
                          loading={isLoadingServiceItemsHealthPlan}
                          disabled={true}
                          onChange={(event, newValue) => {}}
                          popupIcon={
                            <CaretDown size={18} color="white" weight="fill" />
                          }
                          clearIcon={
                            <X size={18} color="white" weight="fill" />
                          }
                          value={serviceItemHealtPlanSelected || null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Selecione uma especialidade"
                              variant="outlined"
                              size="medium"
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  color: "#FFF",
                                  backgroundColor: theme.palette.primary.main,
                                  "&.MuiButtonBase-root": {
                                    color: "#FFF !important",
                                  },
                                  "&.Mui-disabled": {
                                    backgroundColor: "rgba(131, 131, 131, 0.1)",
                                    color: "#353535 !important",
                                    borderColor: "transparent",
                                  },
                                  "&.Mui-disabled fieldset": {
                                    borderColor: "transparent",
                                  },
                                },
                              }}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    ) : (
                      serviceGroupSelected &&
                      serviceGroupSelected.nome.toLowerCase() !== "combos" && (
                        <Grid item lg={12} md={12} xs={12}>
                          <Typography
                            component="h1"
                            variant="h6"
                            sx={{
                              color: (theme) =>
                                !clinicSelected || !serviceGroupSelected
                                  ? theme.palette.neutral.main
                                  : theme.palette.text.primary,
                              fontSize: 16,
                              fontWeight: 600,
                              mb: 1,
                            }}
                          >
                            {serviceGroupSelected.nome.toLowerCase() ===
                            "exames"
                              ? "Selecione um exame"
                              : "Selecione uma especialidade"}
                          </Typography>
                          <Autocomplete
                            id="service-item"
                            options={
                              serviceGroupSelected.nome.toLowerCase() !==
                              "consultas"
                                ? optionsHealthPlan.sort(
                                    (a, b) =>
                                      -b.itemservico.categoriaservico.nome.localeCompare(
                                        a.itemservico.categoriaservico.nome
                                      )
                                  )
                                : optionsHealthPlan
                            }
                            getOptionLabel={(value) => value.itemservico.nome}
                            groupBy={
                              serviceGroupSelected.nome.toLowerCase() !==
                              "consultas"
                                ? (option) =>
                                    option.itemservico.categoriaservico.nome
                                : undefined
                            }
                            loading={isLoadingServiceItemsHealthPlan}
                            disabled={!clinicSelected || !serviceGroupSelected}
                            onInputChange={(e, value) =>
                              onChangeSearchTermDebounced(value)
                            }
                            noOptionsText="Nesta unidade não há disponibilidade, verifique em outra unidade ou ligue para a central"
                            isOptionEqualToValue={(option, value) =>
                              option.iditemservico === value.iditemservico
                            }
                            filterOptions={(options) => options}
                            blurOnSelect
                            onChange={(event, newValue) => {
                              setServiceItemHealthPlanSelected(newValue);
                              handleChangeServiceItemHealtPlan(null);

                              ReactGA.event({
                                category: "agendamento-web",
                                action: "start",
                                label: "categoria-servico", //3
                              });
                            }}
                            renderGroup={(params) => (
                              <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                              </li>
                            )}
                            popupIcon={
                              <CaretDown
                                size={18}
                                color="white"
                                weight="fill"
                              />
                            }
                            clearIcon={
                              <X size={18} color="white" weight="fill" />
                            }
                            value={serviceItemHealtPlanSelected || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={
                                  serviceGroupSelected.nome.toLowerCase() ===
                                  "exames"
                                    ? "Selecione um exame"
                                    : "Selecione uma especialidade"
                                }
                                variant="outlined"
                                size="medium"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    color: "#FFF",
                                    backgroundColor: theme.palette.primary.main,
                                    "&.MuiButtonBase-root": {
                                      color: "#FFF !important",
                                    },
                                    "&.Mui-disabled": {
                                      backgroundColor:
                                        "rgba(131, 131, 131, 0.1)",
                                      color: "#353535 !important",
                                      borderColor: "transparent",
                                    },
                                    "&.Mui-disabled fieldset": {
                                      borderColor: "transparent",
                                    },
                                  },
                                }}
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      )
                    )}
                  </>
                )}
            </Grid>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography
              fontSize={16}
              sx={{
                color: (theme) => theme.palette.onPrimaryContainer.main,
                fontWeight: 600,
                mb: 1,
                textAlign: "center",
              }}
            >
              INFORMAÇÕES IMPORTANTES
            </Typography>
            <Card
              sx={{
                backgroundColor: "rgba(221,227,234,0.2)",
                height: "100%",
              }}
            >
              <CardContent>
                <Grid
                  container
                  spacing={2}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Grid item lg={12} md={12} xs={12}>
                    {!serviceGroupSelected && (
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        color={theme.palette.secondary.main}
                      >
                        <Info size={24} weight="bold" />
                        <Typography
                          fontSize={18}
                          sx={{
                            color: (theme) => theme.palette.secondary.main,
                            fontWeight: 500,
                          }}
                        >
                          Selecione um serviço
                        </Typography>
                      </Stack>
                    )}
                    {serviceGroupSelected &&
                      appointmentType &&
                      serviceGroupSelected.nome.toLowerCase() !== "combos" && (
                        <>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            mb={2}
                          >
                            <Typography
                              fontSize={12}
                              sx={{
                                color: (theme) => theme.palette.text.disabled,
                                fontWeight: 500,
                              }}
                            >
                              Tipo de agendamento:
                            </Typography>
                            {setup && setup.modules.convenio && healthPlan ? (
                              <Tooltip title="Clique aqui para alterar">
                                {appointmentType === "convenio" ? (
                                  <Chip
                                    label={`Convênio - ${healthPlan.nome}`}
                                    color="secondary"
                                    onClick={() =>
                                      setIsOpenAppointmentTypeModal(true)
                                    }
                                  />
                                ) : (
                                  <Chip
                                    label="Particular"
                                    color="primary"
                                    onClick={() =>
                                      setIsOpenAppointmentTypeModal(true)
                                    }
                                  />
                                )}
                              </Tooltip>
                            ) : (
                              <Chip label="Particular" color="primary" />
                            )}
                          </Stack>
                        </>
                      )}

                    {serviceGroupSelected && !clinicSelected && (
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        color={theme.palette.secondary.main}
                      >
                        <Info size={24} weight="bold" />
                        <Typography
                          fontSize={18}
                          sx={{
                            color: (theme) => theme.palette.secondary.main,
                            fontWeight: 500,
                          }}
                        >
                          Selecione uma clínica para{" "}
                          {`${singularizeWithArticle(
                            serviceGroupSelected.nome.toLowerCase()
                          )}`}
                        </Typography>
                      </Stack>
                    )}

                    {serviceGroupSelected && clinicSelected && (
                      <>
                        <Typography
                          fontSize={12}
                          sx={{
                            color: (theme) => theme.palette.text.disabled,
                            fontWeight: 500,
                          }}
                        >
                          Endereço:
                        </Typography>
                        <Typography
                          fontSize={14}
                          sx={{
                            color: (theme) =>
                              theme.palette.onPrimaryContainer.main,
                            fontWeight: 500,
                          }}
                        >
                          {formatClinicAddress(clinicSelected)}
                        </Typography>
                      </>
                    )}
                    {serviceGroupSelected && clinicSelected && (
                      <>
                        <Alert
                          color="warning"
                          icon={false}
                          sx={{
                            mt: 1,
                          }}
                        >
                          Obs: Consultas de retorno devem ser agendadas através
                          do nosso telefone {process.env.REACT_APP_PHONE}
                        </Alert>
                      </>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    {appointmentType === "particular" && (
                      <>
                        {serviceGroupSelected &&
                          clinicSelected &&
                          !serviceItemSelected &&
                          serviceGroupSelected.nome.toLowerCase() !==
                            "combos" && (
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                              color={theme.palette.secondary.main}
                            >
                              <Info size={24} weight="bold" />
                              <Typography
                                fontSize={18}
                                sx={{
                                  color: (theme) =>
                                    theme.palette.secondary.main,
                                  fontWeight: 500,
                                }}
                              >
                                {serviceGroupSelected.nome.toLowerCase() ===
                                "exames"
                                  ? `Qual tipo de exame você precisa?`
                                  : `Qual tipo de consulta você precisa?`}
                              </Typography>
                            </Stack>
                          )}
                        {serviceGroupSelected &&
                          clinicSelected &&
                          serviceItemSelected && (
                            <>
                              <Typography
                                fontSize={12}
                                sx={{
                                  color: (theme) => theme.palette.text.disabled,
                                  fontWeight: 500,
                                }}
                              >
                                Sobre seu agendamento:
                              </Typography>
                              <Typography
                                fontSize={14}
                                sx={{
                                  color: (theme) =>
                                    theme.palette.onPrimaryContainer.main,
                                  fontWeight: 500,
                                }}
                              >
                                {singularizeWithArticle(
                                  serviceGroupSelected.nome.toLowerCase()
                                )}{" "}
                                vai custar{" "}
                                {formatCurrency(
                                  Number(serviceItemSelected.valor)
                                )}{" "}
                                {serviceItemSelected.gruposervico !== null ? (
                                  <>
                                    {serviceItemSelected.gruposervico
                                      .tipospagamentos !== "" && (
                                      <b>
                                        {" "}
                                        -{" "}
                                        {
                                          serviceItemSelected.gruposervico
                                            .tipospagamentos
                                        }
                                      </b>
                                    )}
                                  </>
                                ) : (
                                  serviceItemSelected.categoriaservico
                                    .gruposervico && (
                                    <>
                                      {serviceItemSelected.categoriaservico
                                        .gruposervico.tipospagamentos !==
                                        "" && (
                                        <b>
                                          {" "}
                                          -{" "}
                                          {
                                            serviceItemSelected.categoriaservico
                                              .gruposervico.tipospagamentos
                                          }
                                        </b>
                                      )}
                                    </>
                                  )
                                )}
                              </Typography>
                            </>
                          )}
                      </>
                    )}
                    {setup &&
                      setup.modules.convenio &&
                      appointmentType === "convenio" && (
                        <>
                          {serviceGroupSelected &&
                            clinicSelected &&
                            serviceGroupSelected.nome.toLowerCase() !==
                              "combos" &&
                            !serviceItemHealtPlanSelected && (
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                color={theme.palette.secondary.main}
                              >
                                <Info size={24} weight="bold" />
                                <Typography
                                  fontSize={18}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.secondary.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  {serviceGroupSelected.nome.toLowerCase() ===
                                  "exames"
                                    ? `Qual tipo de exame você precisa?`
                                    : `Qual tipo de consulta você precisa?`}
                                </Typography>
                              </Stack>
                            )}
                          {serviceGroupSelected &&
                            clinicSelected &&
                            serviceItemHealtPlanSelected &&
                            serviceGroupSelected.nome.toLowerCase() !==
                              "combos" && (
                              <>
                                <Typography
                                  fontSize={12}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.text.disabled,
                                    fontWeight: 500,
                                  }}
                                >
                                  Sobre sua consulta:
                                </Typography>
                                <Typography
                                  fontSize={14}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  {singularizeWithArticle(
                                    serviceGroupSelected.nome.toLowerCase()
                                  )}{" "}
                                  vai custar{" "}
                                  {formatCurrency(
                                    Number(serviceItemHealtPlanSelected.valor)
                                  )}{" "}
                                  {serviceItemHealtPlanSelected.itemservico
                                    .gruposervico !== null ? (
                                    <>
                                      {serviceItemHealtPlanSelected.itemservico
                                        .gruposervico.tipospagamentos !==
                                        "" && (
                                        <b>
                                          {" "}
                                          -{" "}
                                          {
                                            serviceItemHealtPlanSelected
                                              .itemservico.gruposervico
                                              .tipospagamentos
                                          }
                                        </b>
                                      )}
                                    </>
                                  ) : (
                                    serviceItemHealtPlanSelected.itemservico
                                      .categoriaservico.gruposervico && (
                                      <>
                                        {serviceItemHealtPlanSelected
                                          .itemservico.categoriaservico
                                          .gruposervico.tipospagamentos !==
                                          "" && (
                                          <b>
                                            {" "}
                                            -{" "}
                                            {
                                              serviceItemHealtPlanSelected
                                                .itemservico.categoriaservico
                                                .gruposervico.tipospagamentos
                                            }
                                          </b>
                                        )}
                                      </>
                                    )
                                  )}
                                </Typography>
                              </>
                            )}
                        </>
                      )}
                  </Grid>

                  {serviceGroupSelected &&
                    clinicSelected &&
                    serviceGroupSelected.nome.toLowerCase() !== "combos" &&
                    (serviceItemSelected || serviceItemHealtPlanSelected) && (
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        justifyContent="end"
                        display="flex"
                      >
                        <Button
                          size="large"
                          variant="contained"
                          color="secondary"
                          fullWidth
                          onClick={() => goToAppointmentNextStep()}
                        >
                          Clique aqui para continuar
                        </Button>
                      </Grid>
                    )}
                  {serviceGroupSelected &&
                    clinicSelected &&
                    serviceGroupSelected.nome.toLowerCase() === "combos" &&
                    setup &&
                    setup.modules.combo && (
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        justifyContent="end"
                        display="flex"
                      >
                        <Button
                          size="large"
                          variant="contained"
                          fullWidth
                          color="secondary"
                          onClick={() => goToAppointmentNextStep()}
                        >
                          Clique aqui para continuar (combo)
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={12} xs={12} mt={4}>
            <Typography
              fontSize={14}
              sx={{
                color: (theme) => theme.palette.onPrimaryContainer.main,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Prezado cliente caso não encontre a sua especialidade médica ou
              exame entre em contato pelo {process.env.REACT_APP_PHONE}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {setup && setup.modules.convenio && (
        <HealthPlanModal
          open={isOpenAppointmentTypeModal || hasRedirectedByHome}
          onClose={() => {
            setServiceGroupSelected(null);
            setClinicSelected(null);
            setServiceItemSelected(null);
            handleChangeAppointmentServiceGroup(null);
            handleChangeAppointmentClinic(null);
            handleChangeAppointmentServiceItem(null);
            setIsOpenAppointmentTypeModal(false);
            handleChangeAppointmentType("particular");
            handleChangeHealthPlan(null);
            handleChangeCategoryHealthPlan(null);
            handleChangeHasRedirectedByHome(false);
          }}
          onConfirm={() => {
            handleChangeHasRedirectedByHome(false);
            setIsOpenAppointmentTypeModal(false);
          }}
        />
      )}
    </Grid>
  );
};
