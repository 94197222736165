import { format } from "date-fns";
import React, { createContext, useState } from "react";
import { api } from "../services/api";
import {
  IAppointment,
  IAssociatedItem,
  ICategoryHealthPlan,
  IClinic,
  IHealthPlan,
  IPatient,
  IPayServiceOrderResponse,
  IPaymentCardForm,
  IPaymentMethod,
  IProfessionalSchedules,
  IPromotionalItem,
  IServiceGroup,
  IServiceItem,
  IServiceOrderItem,
  IServiceOrderResponse,
  IServicePackage,
  TimeType,
} from "../types";

type AppointmentContextProps = {
  clinic: IClinic | null;
  serviceGroup: IServiceGroup | null;
  serviceItem: IServiceItem | null;
  professional: IProfessionalSchedules | null;
  timeType: TimeType;
  date: string;
  vacancy: string | undefined;
  dateSelected: Date;
  dependent: IPatient | null;
  account: number;
  hasFinalize: boolean;
  paymentMethod: IPaymentMethod | null;
  servicePackage: IServicePackage | null;
  serviceOrder: IServiceOrderResponse | null;
  paymentCardForm: IPaymentCardForm | null;
  serviceOrderItem: IServiceOrderItem | null;
  isFinishingServiceOrder: boolean;
  isCreatingAppointment: boolean;
  hasRedirectedByHome: boolean;
  appointmentStep: number;
  selectedDate: Date;
  paymentType: "online" | "presential" | null;
  appointmentType: "particular" | "convenio" | null;
  healthPlan: IHealthPlan | null;
  categoryHealthPlan: ICategoryHealthPlan | null;
  serviceItemHealtPlan: IAssociatedItem | null;
  promotionalItem: IPromotionalItem | null;
  beforeInstallPromptEvent: any;
  handleBeforeInstallPromptEventFunction: (event: any) => void;
  handleChangeAppointmentServiceGroup: (group: IServiceGroup | null) => void;
  handleChangeAppointmentClinic: (clinic: IClinic | null) => void;
  handleChangeAppointmentServiceItem: (specialty: IServiceItem | null) => void;
  handleChangeAppointmentServiceOrderItem: (
    order: IServiceOrderItem | null
  ) => void;
  handleChangeAppointmentProfessional: (data: {
    professional: IProfessionalSchedules;
    date: string;
    vacancy: string | undefined;
    dateSelected: Date;
    timeType: TimeType;
  }) => void;
  handleChangeAppointmentProfessionalClear: () => void;
  handleChangeAppointmentPaymentMethod: (
    methodPayment: IPaymentMethod | null
  ) => void;
  handleChangeAppointmentAccount: (data: {
    account: number;
    dependent: IPatient | null;
  }) => void;
  handleChangeAppointmentServicePackage: (
    servicePackage: IServicePackage | null
  ) => void;
  handleChangeAppointmentServiceOrder: (
    serviceOrder: IServiceOrderResponse | null
  ) => void;
  handleChangePaymentCardForm: (value: IPaymentCardForm | null) => void;
  handleChangeFinalize: (value: boolean) => void;
  handleCreateServiceOrder: (data: {
    patientId: string;
    applicationId: string;
    isCombo?: boolean;
    email: string;
    userId?: string;
    serviceChannelId: string;
  }) => Promise<IPayServiceOrderResponse>;
  handleResetServiceOrder: () => void;
  handleChangePaymentType: (value: "online" | "presential" | null) => void;
  handleChangeAppointmentStep: (value: number) => void;
  handleChangeSelectedDate: (value: Date) => void;
  handleChangeAppointmentType: (
    value: "particular" | "convenio" | null
  ) => void;
  handleCreateAppointment: ({
    applicationId,
    patientId,
    userId,
    email,
    serviceChannelId,
    isCombo,
  }: {
    patientId: string;
    applicationId: string;
    email: string;
    userId?: string;
    serviceChannelId: string;
    isCombo?: boolean;
  }) => Promise<IAppointment>;
  handleChangeHealthPlan: (value: IHealthPlan | null) => void;
  handleChangeServiceItemHealtPlan: (value: IAssociatedItem | null) => void;
  handleChangeCategoryHealthPlan: (value: ICategoryHealthPlan | null) => void;
  handleChangeHasRedirectedByHome: (value: boolean) => void;
  handleChangePromotionalItem: (value: IPromotionalItem | null) => void;
  handleReset: () => void;
};

export const AppointmentContext = createContext<AppointmentContextProps>(
  {} as AppointmentContextProps
);

type ApointmentProviderProps = {
  children: React.ReactNode;
};

export const AppointmentProvider: React.FC<ApointmentProviderProps> = ({
  children,
}) => {
  const [clinic, setClinic] = useState<IClinic | null>(null);
  const [beforeInstallPromptEvent, setBeforeInstallPromptEvent] = useState(null)
  const [serviceGroup, setServiceGroup] = useState<IServiceGroup | null>(null);
  const [serviceItem, setServiceItem] = useState<IServiceItem | null>(null);
  const [professional, setProfessional] =
    useState<IProfessionalSchedules | null>(null);
  const [account, setAccount] = useState<number>(0);
  const [dependent, setDependent] = useState<IPatient | null>(null);
  const [timeType, setTimeType] = useState<TimeType>("");
  const [dateSelected, setDateSelected] = useState<Date>(new Date());
  const [date, setDate] = useState<string>("");
  const [vacancy, setVacancy] = useState<string | undefined>(undefined);

  const [servicePackage, setServicePackage] = useState<IServicePackage | null>(
    null
  );
  const [serviceOrder, setServiceOrder] =
    useState<IServiceOrderResponse | null>(null);
  const [isFinishingServiceOrder, setIsFinishingServiceOrder] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | null>(
    null
  );
  const [paymentType, setPaymentType] = useState<
    "online" | "presential" | null
  >("presential");
  const [appointmentType, setAppointmentType] = useState<
    "particular" | "convenio" | null
  >("particular");

  const [serviceItemHealtPlan, setServiceItemHealthPlan] =
    useState<IAssociatedItem | null>(null);

  const [healthPlan, setHealthPlan] = useState<IHealthPlan | null>(null);
  const [promotionalItem, setPromotionalItem] =
    useState<IPromotionalItem | null>(null);
  const [categoryHealthPlan, setCategoryHealthPlan] =
    useState<ICategoryHealthPlan | null>(null);

  const [serviceOrderItem, setServiceOrderItem] =
    useState<IServiceOrderItem | null>(null);

  const [paymentCardForm, setPaymentCardForm] =
    useState<IPaymentCardForm | null>(null);
  const [appointmentStep, setAppointmentStep] = useState(0);

  const [selectedDate, setSelectedDate] = useState<Date>(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    )
  );

  const [hasFinalize, setHasFinalize] = useState(false);
  const [isCreatingAppointment, setIsCreatingAppointment] = useState(false);
  const [hasRedirectedByHome, setHasRedirectedByHome] =
    useState<boolean>(false);

  const handleChangeServiceItemHealtPlan = (value: IAssociatedItem | null) => {
    setServiceItemHealthPlan(value);
  };

  const handleReset = () => {
    setClinic(null);
    setServiceGroup(null);
    setServiceItem(null);
    setProfessional(null);
    setAccount(0);
    setDependent(null);
    setTimeType("time");
    setServicePackage(null);
    setServiceOrder(null);
    setPaymentMethod(null);
    setPaymentType(null);
    setAppointmentType("particular");
    setServiceItemHealthPlan(null);
    setHealthPlan(null);
    setPromotionalItem(null);
    setCategoryHealthPlan(null);
    setServiceOrderItem(null);
    setPaymentCardForm(null);
    setAppointmentStep(0);
  };

  const handleChangeHasRedirectedByHome = (value: boolean) => {
    setHasRedirectedByHome(value);
  };

  const handleChangeHealthPlan = (value: IHealthPlan | null) => {
    setHealthPlan(value);
  };

  const handleChangePromotionalItem = (value: IPromotionalItem | null) => {
    setPromotionalItem(value);
  };

  const handleChangeCategoryHealthPlan = (
    value: ICategoryHealthPlan | null
  ) => {
    setCategoryHealthPlan(value);
  };

  const handleChangeAppointmentServiceGroup = async (
    group: IServiceGroup | null
  ) => {
    setServiceGroup(group);
  };

  const handleChangeAppointmentType = (
    value: "particular" | "convenio" | null
  ) => {
    setAppointmentType(value);
  };

  const handleChangeFinalize = (value: boolean) => {
    setHasFinalize(value);
  };

  const handleChangeSelectedDate = (value: Date) => {
    setSelectedDate(value);
  };

  const handleChangeAppointmentStep = (value: number) => {
    setAppointmentStep(value);
  };

  const handleChangePaymentCardForm = (value: IPaymentCardForm | null) => {
    setPaymentCardForm(value);
  };

  const handleChangePaymentType = (value: "online" | "presential" | null) => {
    setPaymentType(value);
  };

  const handleChangeAppointmentServiceOrderItem = async (
    order: IServiceOrderItem | null
  ) => {
    setServiceOrderItem(order);
  };

  const handleChangeAppointmentServiceOrder = async (
    serviceOrder: IServiceOrderResponse | null
  ) => {
    setServiceOrder(serviceOrder);
  };
  const handleBeforeInstallPromptEventFunction = async (event: any) => {
    setBeforeInstallPromptEvent(event);
  };

  const handleChangeAppointmentClinic = async (clinic: IClinic | null) => {
    setClinic(clinic);
  };

  const handleChangeAppointmentServiceItem = async (
    specialty: IServiceItem | null
  ) => {
    setServiceItem(specialty);
  };

  const handleChangeAppointmentProfessional = async ({
    date,
    dateSelected,
    professional,
    timeType,
    vacancy,
  }: {
    professional: IProfessionalSchedules;
    date: string;
    vacancy: string | undefined;
    dateSelected: Date;
    timeType: TimeType;
  }) => {
    setProfessional(professional);
    setDate(date);
    setVacancy(vacancy);
    setDateSelected(dateSelected);
    setTimeType(timeType);
  };

  const handleChangeAppointmentPaymentMethod = async (
    paymentMethod: IPaymentMethod | null
  ) => {
    setPaymentCardForm(null);
    setPaymentMethod(paymentMethod);
  };

  const handleChangeAppointmentAccount = async ({
    account,
    dependent,
  }: {
    account: number;
    dependent: IPatient | null;
  }) => {
    setAccount(account);
    setDependent(dependent);
  };

  const handleChangeAppointmentProfessionalClear = async () => {
    setProfessional(null);
  };

  const handleChangeAppointmentServicePackage = async (
    servicePackage: IServicePackage | null
  ) => {
    setServicePackage(servicePackage);
  };

  const handleCreateServiceOrder = async ({
    applicationId,
    patientId,
    isCombo,
    userId,
    email,
    serviceChannelId,
  }: {
    patientId: string;
    applicationId: string;
    isCombo?: boolean;
    email: string;
    userId?: string;
    serviceChannelId: string;
  }) => {
    return new Promise<IPayServiceOrderResponse>(async (resolve, reject) => {
      try {
        setIsFinishingServiceOrder(true);
        let body;
        if (paymentMethod?.formapagamento === "cartao") {
          body = {
            credit_card_number: paymentCardForm?.cardNumber,
            credit_card_owner_name: paymentCardForm?.cardOwnerName,
            credit_card_expiration_date: paymentCardForm?.expirationDate,
            credit_card_cvv: paymentCardForm?.securityCode,
            debit: paymentCardForm?.debit,
            brand: paymentCardForm?.brand,
          };
        }

        const serviceItemApp: { id?: string; quantidade: number }[] = [];
        if (isCombo && servicePackage) {
          for (const itemCombo of servicePackage.itenspromocionais) {
            serviceItemApp.push({
              id: itemCombo.iditempromocional,
              quantidade: 1,
            });

            const promotionalItemAdditional =
              itemCombo && itemCombo.itemsadicionais.length > 0
                ? servicePackage.itenspromocionais.filter(
                    (item) =>
                      item.itemservico.agendamento &&
                      itemCombo.itemsadicionais
                        .map((addItem) => addItem.iditemservico)
                        .includes(item.iditemservico)
                  )
                : null;

            if (
              promotionalItemAdditional &&
              promotionalItemAdditional.length > 0
            ) {
              promotionalItemAdditional.forEach((add) => {
                serviceItemApp.push({
                  id: add.iditempromocional,
                  quantidade: 1,
                });
              });
            }
          }
          body = {
            ...body,
            isCombo,
          };
        } else {
          serviceItemApp.push({
            id:
              appointmentType === "convenio"
                ? serviceItemHealtPlan?.id
                : serviceItem?.iditemservico,
            quantidade: 1,
          });

          body = {
            ...body,
            data: date,
            idagenda: professional?.agenda.idagenda,
            idcanalatendimento: serviceChannelId,
            idprofissional: professional?.idprofissional,
            vacancy,

            horario: format(
              new Date(
                new Date(
                  professional ? professional.timetable[0].timeLabel.date : ""
                )
                  .toISOString()
                  .slice(0, -1)
              ),
              "HH:mm"
            ),
          };
        }

        const res = await api.post<{
          data: IPayServiceOrderResponse;
          sucess: boolean;
        }>(`/users-patients/service-orders`, {
          payment_method_id: paymentMethod?.idmetodopagamento,
          payment_type: paymentMethod?.formapagamento,
          installments: paymentCardForm?.installment
            ? paymentCardForm.installment
            : 1,
          customer_document: paymentCardForm?.userDocument,
          customer_phone_number: paymentCardForm?.phoneNumber,
          billing_city: "Cametá",
          billing_address: "R São Benedito",
          billing_number: "801",
          billing_neighborhood: "Castanhal",
          billing_state: "PA",
          billing_zipcode: "68400000",
          idpaciente: patientId,
          idclinica: clinic?.idclinica,
          idcombo: servicePackage?.idcombo,
          idusuario: applicationId,
          idresponsavel: userId,
          prioritario: false,
          generateserviceorder: false,
          email,
          serviceItems: serviceItemApp,
          forcado: false,
          retornoforcado: false,
          ...body,
        });

        setIsFinishingServiceOrder(false);
        resolve(res.data.data);
      } catch (error: any) {
        setIsFinishingServiceOrder(false);
        reject(error);
      }
    });
  };

  const handleCreateAppointment = async ({
    applicationId,
    patientId,
    userId,
    email,
    serviceChannelId,
    isCombo = false,
  }: {
    patientId: string;
    applicationId: string;
    email: string;
    userId?: string;
    serviceChannelId: string;
    isCombo?: boolean;
  }) => {
    return new Promise<IAppointment>(async (resolve, reject) => {
      try {
        setIsCreatingAppointment(true);

        const serviceItemApp: { id?: string; quantidade: number }[] = [];
        if (isCombo) {
          const item = servicePackage?.itenspromocionais.find(
            (item) => item.iditemservico === serviceItem?.iditemservico
          );

          if (!item) {
            return reject("O item selecionado não pertence ao combo escolhido");
          }

          if (promotionalItem) {
            serviceItemApp.push({
              id: promotionalItem.iditempromocional,
              quantidade: 1,
            });

            const promotionalItemAdditional =
              servicePackage &&
              promotionalItem &&
              promotionalItem.itemsadicionais.length > 0
                ? servicePackage.itenspromocionais.filter(
                    (item) =>
                      item.itemservico.agendamento &&
                      promotionalItem.itemsadicionais
                        .map((addItem) => addItem.iditemservico)
                        .includes(item.iditemservico)
                  )
                : null;

            if (
              promotionalItemAdditional &&
              promotionalItemAdditional.length > 0
            ) {
              promotionalItemAdditional.forEach((add) => {
                serviceItemApp.push({
                  id: add.iditempromocional,
                  quantidade: 1,
                });
              });
            }
          }
        } else {
          serviceItemApp.push({
            id:
              appointmentType === "convenio"
                ? serviceItemHealtPlan?.id
                : serviceItem?.iditemservico,
            quantidade: 1,
          });
        }

        const res = await api.post<{ success: boolean; data: IAppointment }>(
          `/users-patients/appointments`,
          {
            idusuario: applicationId,
            idresponsavel: userId,
            data: date,
            prioritario: false,
            generateserviceorder: false,
            email,
            idagenda: professional?.agenda.idagenda,
            idcanalatendimento: serviceChannelId,
            idpaciente: patientId,
            idprofissional: professional?.idprofissional,
            serviceItems: serviceItemApp,
            forcado: false,
            retornoforcado: false,
            vacancy,
            horario: format(
              new Date(
                new Date(
                  professional ? professional.timetable[0].timeLabel.date : ""
                )
                  .toISOString()
                  .slice(0, -1)
              ),
              "HH:mm"
            ),
          }
        );

        setIsCreatingAppointment(false);
        resolve(res.data.data);
      } catch (error: any) {
        setIsCreatingAppointment(false);
        reject(error);
      }
    });
  };

  const handleResetServiceOrder = () => {
    setServiceOrder(null);
    setPaymentMethod(null);
    setPaymentCardForm(null);
  };

  return (
    <AppointmentContext.Provider
      value={{
        beforeInstallPromptEvent,
        clinic,
        professional,
        serviceGroup,
        serviceItem,
        timeType,
        date,
        dateSelected,
        account,
        dependent,
        vacancy,
        handleChangeAppointmentServiceGroup,
        paymentMethod,
        handleBeforeInstallPromptEventFunction,
        handleChangeAppointmentClinic,
        handleChangeAppointmentServiceItem,
        handleChangeAppointmentProfessional,
        handleChangeAppointmentPaymentMethod,
        handleChangeAppointmentAccount,
        handleChangeAppointmentProfessionalClear,
        handleChangeAppointmentServicePackage,
        servicePackage,
        handleChangeAppointmentServiceOrder,
        serviceOrder,
        handleChangePaymentCardForm,
        paymentCardForm,
        handleChangeFinalize,
        hasFinalize,
        handleCreateServiceOrder,
        handleResetServiceOrder,
        isFinishingServiceOrder,
        handleChangeAppointmentServiceOrderItem,
        serviceOrderItem,
        handleChangePaymentType,
        paymentType,
        appointmentStep,
        handleChangeAppointmentStep,
        handleChangeSelectedDate,
        selectedDate,
        appointmentType,
        handleChangeAppointmentType,
        isCreatingAppointment,
        handleCreateAppointment,
        categoryHealthPlan,
        handleChangeCategoryHealthPlan,
        handleChangeHealthPlan,
        handleChangeServiceItemHealtPlan,
        healthPlan,
        serviceItemHealtPlan,
        handleChangeHasRedirectedByHome,
        hasRedirectedByHome,
        handleChangePromotionalItem,
        promotionalItem,
        handleReset,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};
