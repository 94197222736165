import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    debounce,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MobileStepper,
    styled,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
import React from "react";
import mockupOne from "../../assets/mockup-one.png"
import mockupTwo from "../../assets/mockup-two (1).png"
import mockupThree from "../../assets/mockup-three.png"
import mockupFinal from "../../assets/mockup-final.png"
  interface IProps {
    open: boolean;
    close: Function;
  }
  export default function TourIosPwa({
    open, close
  }: IProps) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const selectImg = (index: number): string  => {
      switch(index) {
        case 0:
          return mockupOne
        case 1:
          return mockupTwo
        case 2: 
          return mockupThree
        default:
          return mockupFinal
      }
    }
    const selectTitle = (index: number): string  => {
      switch(index) {
        case 0:
          return "Passo 1: clique no icone de compartilhar"
        case 1:
          return "Passo 2: clique em adicionar à tela de início"
        case 2: 
          return "Passo 3: clique em adicionar, no canto superior"
        default:
          return "App instalado com sucesso !"
      }
    }
    return (
      <>
       <Dialog
          open={open}
          onClose={() => close()}
          fullWidth
          maxWidth="xl"
        >
          <Grid container alignItems="center" justifyContent="center">
            <DialogTitle style={{ padding: "0", width: 300, textAlign: "center"}}>{selectTitle(activeStep)}</DialogTitle>
          </Grid>
          <Grid item style={{display: "flex", justifyContent: "center"}}>
            <img src={selectImg(activeStep)} style={{maxWidth: "79%"}} alt="imagem mockup" />
          </Grid>
          <DialogActions style={{ justifyContent: "center", padding: 0 }}>
          <MobileStepper
            variant="progress"
            steps={4}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: 500, flexGrow: 1 }}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === 3}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </Button>
            }
          />
          </DialogActions>
        </Dialog>
      </>
    );
  }
  