import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../../services/api";
import { AppointmentType } from "../../types";
import {
  createUseAppointmentByIdKey,
  createUseAppointmentByResponsibleIdKey,
  createUseAppointmentKey,
  createUseServiceOrderIdKey,
} from "./key";

export const useAppointments = (
  {
    patientId,
    clinicId,
    attended = false,
  }: {
    patientId: string;
    clinicId?: string;
    attended?: boolean;
  },
  options?: UseQueryOptions<AppointmentType[]>
) => {
  return useQuery(
    createUseAppointmentKey({
      patientId,
      clinicId,
      attended,
    }),
    () =>
      api
        .get(
          `/user-patients/${patientId}/appointment${
            clinicId ? `?idclinica=${clinicId}` : ""
          }${attended ? `?attended=${attended}` : ""}`
        )
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

export const useAppointmentsByResponsibleId = (
  {
    patientId,
  }: {
    patientId: string;
  },
  options?: UseQueryOptions<AppointmentType[]>
) => {
  return useQuery(
    createUseAppointmentByResponsibleIdKey({
      patientId,
    }),
    () =>
      api
        .post("/user-patients/dependents/appointments", {
          idresponsavel: patientId,
        })
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

export const useAppointmentById = (
  {
    appointmentId,
  }: {
    appointmentId: string;
  },
  options?: UseQueryOptions<AppointmentType>
) => {
  return useQuery(
    createUseAppointmentByIdKey({
      appointmentId,
    }),
    () =>
      api
        .get(`/user-patients/appointments/${appointmentId}`)
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

export const useServiceOrderStatusById = (
  {
    serviceOrderId,
  }: {
    serviceOrderId: string;
  },
  options?: UseQueryOptions<AppointmentType>
) => {
  return useQuery(
    createUseServiceOrderIdKey({
      serviceOrderId,
    }),
    () =>
      api
        .get(`/users-patients/service-orders/:${serviceOrderId}`)
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
    }
  );
};

export const fetchOrderStatus = async ({
  serviceOrderId,
}: {
  serviceOrderId?: string;
}) => {
  try {
    const response = await api.get(
      `/users-patients/service-orders/${serviceOrderId}`
    ); // Substitua a URL pela sua API real
    return response.data.data;
  } catch (error) {
    console.error("Erro ao buscar o status da ordem de serviço:", error);
  }
};
