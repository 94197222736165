import {
  AppBar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { CaretLeft } from "phosphor-react";
import { FormEvent, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import LogoSection from "../../../components/LogoSection";
import { useAuth } from "../../../hooks/useAuth";
import { MainContainer } from "../../../layout/MainContainer";
import { api } from "../../../services/api";
import { useAuthenticatedUser } from "../../../store/user";
import { maskCpf, removeMaskCpf } from "../../../utils/masks";
import { validateCpf } from "../../../utils/roles";

const USER_DOCUMENT_MAX_LENGTH = 11;

export default function ForgotPassword() {
  const location = useLocation() 
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down("md"));
  const { data: user } = useAuthenticatedUser();
  const [searchparams] = useSearchParams();
  const navigate = useNavigate();

  const { signOut, isLoading } = useAuth();

  // data register
  const [cpf, setCpf] = useState<string>("");
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [email, setEmail] = useState<string>("");

  const [processing, setProcessing] = useState<boolean>(false);

  // errors messages

  const [cpfError, setCpfError] = useState<string>(" ");
  const [birthDateError, setBirthDateError] = useState<string>(" ");
  const [emailError, setEmailError] = useState<string>(" ");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setProcessing(true);
    setCpfError(" ");
    setBirthDateError(" ");
    setEmailError(" ");

    if (cpf === "") {
      setCpfError("O CPF é obrigatório");
      setProcessing(false);
      return;
    }
    if (!validateCpf(cpf)) {
      setCpfError("Digite um CPF válido");
      setProcessing(false);
      return;
    }
    if (!birthDate) {
      setBirthDateError("A data de nascimento é orbigatório");
      setProcessing(false);
      return;
    }
    if (email === "") {
      setEmailError("O e-mail é obrigatório");
      setProcessing(false);
      return;
    }

    try {
      await api.post("/users-patients/forgotpassword", {
        email,
        cpf,
        datanascimento: format(birthDate, "yyyy-MM-dd"),
      });

      toast.success("Uma nova senha foi enviada para seu email", {
        position: "top-right",
      });
      let url =''
      if(searchparams.get("redirect")) url+=`?redirect=${searchparams.get("redirect")}`

      navigate("/login" + url, {
        replace: true,
        state: {
          email
        }
      });
      if (user) {
        await signOut();
      }
      setProcessing(false);
      

    } catch (error: any) {
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setProcessing(false);
    }
  };

  const onChangeUserDocument = (value: string) => {
    value = removeMaskCpf(value);
    const regex = /^([0-9.-]+)+$/;
    if (value.length > USER_DOCUMENT_MAX_LENGTH) {
      return;
    }
    setCpf(value);
    setCpfError(" ");

    if (!value) {
      return setCpfError("O CPF é obrigatório");
    }

    if (!validateCpf(value)) {
      return setCpfError("O CPF é inválido");
    }

    if (!regex.test(value)) {
      return setCpfError("Revise este dado");
    }
  };

  return (
    <>
      <MainContainer theme={theme}>
        <AppBar
          position="relative"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.primary.main,
          }}
        >
          <Container maxWidth="lg">
            <Toolbar
              disableGutters
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {!matchSM && (
                <Button
                  color="inherit"
                  sx={{
                    position: "absolute",
                    left: 0,
                    color: theme.palette.onPrimary.main,
                  }}
                  onClick={() => navigate(-1)}
                  startIcon={
                    <CaretLeft size={16} color={theme.palette.onPrimary.main} />
                  }
                >
                  Voltar
                </Button>
              )}
              <LogoSection />
            </Toolbar>
          </Container>
        </AppBar>
        <Container maxWidth="lg" onSubmit={handleSubmit} component="form">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
            mt={2}
          >
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Stack spacing={1}>
                        <Typography
                          component="h1"
                          variant="h6"
                          fontWeight={600}
                          sx={{
                            color: (theme) => theme.palette.text.primary,
                            fontSize: 18,
                          }}
                        >
                          Recuperar senha
                        </Typography>
                        <Typography
                          fontSize={14}
                          sx={{
                            color: (theme) =>
                              theme.palette.onSurfaceVariant.main,
                            fontWeight: 400,
                          }}
                        >
                          Preencha os campos abaixo e enviaremos instruções para
                          você redefinir sua senha por e-mail.
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} mt={4}>
                      <TextField
                        id="email"
                        name="email"
                        label="E-mail"
                        size="medium"
                        placeholder="Digite seu e-mail"
                        fullWidth
                        value={email}
                        error={emailError !== " "}
                        helperText={emailError}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        placeholder="Digite seu CPF"
                        label="CPF"
                        fullWidth
                        value={maskCpf(cpf)}
                        error={cpfError !== " "}
                        helperText={cpfError}
                        type="tel"
                        onChange={(e) => onChangeUserDocument(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <DesktopDatePicker
                        label="Data de nascimento"
                        onChange={(newValue) => setBirthDate(newValue)}
                        value={birthDate}
                        disableFuture
                        sx={{
                          width: "100%",
                        }}
                      />
                      {birthDateError !== " " && (
                        <FormHelperText error>{birthDateError}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Grid item lg={6} md={8} sm={12} xs={12} mt={4}>
              <Button
                disabled={isLoading || processing}
                variant="contained"
                size="large"
                fullWidth
                type="submit"
              >
                {isLoading || processing ? (
                  <CircularProgress color="inherit" size={26} />
                ) : (
                  `Continuar`
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
            pb={4}
          >
            <Grid item lg={5} md={8} sm={12} xs={12} mt={2}>
              <Button
                variant="text"
                size="large"
                fullWidth
                onClick={() => navigate(-1)}
              >
                Lembrei minha senha
              </Button>
            </Grid>
          </Grid>
        </Container>
      </MainContainer>
    </>
  );
}
