import {
  createUseApplicationIdKey,
  createUseDependenceKey,
  createUseUserAuthKey
} from "./keys";
// import { IUser } from "../../models/user";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { api } from "../../services/api";
import { DependentType, IDependentForm, UserType } from "../../types";

export const useAuthenticatedUser = (options?: UseQueryOptions<UserType>) => {
  const { isAuthenticated } = useAuth();
  const isToken = isAuthenticated();
  return useQuery(
    createUseUserAuthKey(),
    () => api.get(`/users-patients/me`).then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
      enabled: isToken,
      refetchOnWindowFocus: false
    }
  );
};

export const useApplicationId = (
  options?: UseQueryOptions<{
    idcanalatendimento: string;
    idusuario: string;
    idcanalorigem: string;
  }>
) => {
  const { isAuthenticated } = useAuth();
  const isToken = isAuthenticated();
  return useQuery(
    createUseApplicationIdKey(),
    () => api.get(`/users-patients/web`).then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
      enabled: isToken,
      refetchOnWindowFocus: false
    }
  );
};

export const useDependents = (
  {
    cpfresponsavel,
  }: {
    cpfresponsavel: string;
  },
  options?: UseQueryOptions<DependentType[]>
) => {
  return useQuery(
    createUseDependenceKey(cpfresponsavel),
    () =>
      api
        .get(`/users-patients/dependents?cpfresponsavel=${cpfresponsavel}`)
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 2 * 60,
      refetchOnWindowFocus: false
    }
  );
};

export const useCreateDependent = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dependent }: { dependent: IDependentForm }) =>
      api
        .post(`/users-patients/dependents`, dependent)
        .then((res) => {
          toast.success("Dependente cadastrado com sucesso", {
            position: "top-right",
          });
          return res.data.data;
        })
        .catch((err) => {
          if (err.response) {
            return toast.error(err.response.data.error.message);
          }
          return toast.error("Ocorreu um erro ao criar o ingresso");
        }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["useDependenceKey"]);
      },
    }
  );
};

export const useUpdateDependent = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      cpf,
      cpfresponsavel,
      telefonecelular,
      nomeresponsavel,
    }: {
      cpf?: string;
      nomeresponsavel?: string;
      cpfresponsavel?: string;
      telefonecelular?: string;
    }) =>
      api
        .put(`/users-patients/dependents`, {
          cpf,
          cpfresponsavel,
          telefonecelular,
          nomeresponsavel,
        })
        .then((res) => {
          toast.success("Dependente vinculado com sucesso", {
            position: "top-right",
          });
          return res.data.data;
        })
        .catch((err) => {
          if (err.response) {
            return toast.error(err.response.data.error.message);
          }
          return toast.error("Ocorreu um erro ao criar o ingresso");
        }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["useDependenceKey"]);
      },
    }
  );
};
