import {
  AppBar,
  Avatar,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { CaretLeft, EnvelopeSimple, Lock } from "phosphor-react";
import { FormEvent, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import LogoSection from "../../../components/LogoSection";
import { useAppointment } from "../../../hooks/useAppointment";
import { useAuth } from "../../../hooks/useAuth";
import { MainContainer } from "../../../layout/MainContainer";
import { formatName } from "../../../utils/masks";
import { validateEmail } from "../../../utils/roles";

import DoctorFemIcon from "../../../assets/images/med-fem.jpg";
import DoctorMascIcon from "../../../assets/images/med-masc.jpg";

export default function Login() {
  const theme = useTheme();
  const location = useLocation() 
  const emailRecovered = location.state?.email
  const matchSM = useMediaQuery(theme.breakpoints.down("md"));
  const [searchparams] = useSearchParams();
  const navigate = useNavigate();
  const { professional, servicePackage, dateSelected, timeType } =
    useAppointment();

  const { signIn, isLoading } = useAuth();

  // states
  const [email, setEmail] = useState(emailRecovered ? emailRecovered :"");
  const [password, setPassword] = useState("");

  // errors
  const [emailError, setEmailError] = useState(" ");
  const [passwordError, setPasswordError] = useState(" ");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setEmailError(" ");
    setPasswordError(" ");
    if (email === "") {
      return setEmailError("e-mail é obrigatório");
    }

    if (!validateEmail(email)) {
      return setEmailError("e-mail inválido");
    }

    if (password === "") {
      return setPasswordError("senha é obrigatório");
    }

    try {
      const result = await signIn(email, password);

      if (result) {
        if (professional) {
          return navigate(-1);
        }

        if (servicePackage) {
          return navigate(-1);
        }
        //verificar se existe parametro
        if (searchparams.get("redirect")) {
          navigate(searchparams.get("redirect") || "/", {
            replace: true,
          });
        } else {
          navigate("/paciente/dashboard", {
            replace: true,
          });
        }
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      }
    }
  };

  useEffect(() => {
    ReactGA.event({
      category: "agendamento-web",
      action: "start",
      label: "login",
    });
  }, [navigate]);

  return (
    <>
      <MainContainer theme={theme}>
        <AppBar
          position="relative"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.primary.main,
          }}
        >
          <Container maxWidth="lg">
            <Toolbar
              disableGutters
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {!matchSM && (
                <Button
                  color="inherit"
                  sx={{
                    position: "absolute",
                    left: 0,
                    color: theme.palette.onPrimary.main,
                  }}
                  onClick={() => navigate(-1)}
                  startIcon={
                    <CaretLeft size={16} color={theme.palette.onPrimary.main} />
                  }
                >
                  Voltar
                </Button>
              )}
              <LogoSection />
            </Toolbar>
          </Container>
        </AppBar>
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: "85vh",
            alignItems: "center",
            justifyContent: "center",
          }}
          onSubmit={handleSubmit}
          component="form"
        >
          {professional && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              display="flex"
              mb={4}
            >
              <Grid
                item
                lg={5}
                md={5}
                sm={12}
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Avatar
                  src={
                    professional.sexoprofissional?.toLowerCase() === "masculino"
                      ? DoctorMascIcon
                      : DoctorFemIcon
                  }
                  sx={{
                    width: 46,
                    height: 46,
                  }}
                  style={{
                    border: `0.1px solid ${theme.palette.primary.main}`,
                  }}
                  alt="professional profile"
                />
                <Stack ml={2}>
                  <Typography
                    fontSize={16}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      fontWeight: 600,
                    }}
                  >
                    {formatName(professional.nomeprofissional)}
                  </Typography>
                  {timeType === "time" ? (
                    <Typography
                      fontSize={12}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                        fontWeight: 500,
                      }}
                    >
                      {`Data e hora: ${format(
                        new Date(dateSelected.toISOString().slice(0, -1)),
                        "dd/MM/yyyy '`as' H:mm"
                      )}`}
                    </Typography>
                  ) : (
                    <Typography
                      fontSize={12}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                        fontWeight: 500,
                      }}
                    >
                      {`Data e hora: ${format(
                        new Date(
                          new Date(
                            professional
                              ? professional.timetable[0].timeLabel.date
                              : new Date()
                          )
                            .toISOString()
                            .slice(0, -1)
                        ),
                        "dd/MM/yyyy 'a partir de' HH:mm"
                      )} - Por ordem de chegada`}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Grid item lg={5} md={8} sm={12} xs={12}>
              <Typography
                fontSize={28}
                sx={{
                  color: (theme) => theme.palette.onPrimaryContainer.main,
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                {professional
                  ? `Finalize seu agendamento agora`
                  : servicePackage
                  ? `Finalize seu agendamento do seu combo agora`
                  : `Acesse sua conta agora`}
              </Typography>
              <Typography
                fontSize={16}
                sx={{
                  color: (theme) => theme.palette.onPrimaryContainer.main,
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                {professional || servicePackage
                  ? `Para finalizar o agendamento, entre em sua conta`
                  : `Conectamos pessoas a profissionais da saúde em um click`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Grid item lg={5} md={8} sm={12} xs={12} mt={4}>
              <TextField
                variant="outlined"
                size="medium"
                placeholder="Digite seu e-mail"
                label="E-mail"
                fullWidth
                autoComplete="email"
                value={email}
                error={emailError !== " "}
                helperText={emailError}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                margin="dense"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EnvelopeSimple size={24} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Grid
              item
              lg={5}
              md={8}
              sm={12}
              xs={12}
              mt={4}
              justifyContent="center"
              alignItems="end"
              display="flex"
              flexDirection="column"
            >
              <TextField
                variant="outlined"
                size="medium"
                placeholder="Digite sua senha"
                label="Senha"
                fullWidth
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={passwordError !== " "}
                helperText={passwordError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock size={24} />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography fontSize={14} variant="h1" mt={1}>
                <Link
                  component="button"
                  type="button"
                  onClick={() =>
                    navigate(
                      searchparams.get("redirect")
                        ? `/recuperar-senha?redirect=${searchparams.get(
                            "redirect"
                          )}`
                        : `/recuperar-senha`
                    )
                  }
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  Esqueci minha senha
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Grid item lg={5} md={8} sm={12} xs={12} mt={4}>
              <Button
                disabled={isLoading}
                variant="contained"
                size="large"
                fullWidth
                type="submit"
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={26} />
                ) : (
                  `Entrar`
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Grid item lg={5} md={8} sm={12} xs={12} mt={2}>
              <Button
                variant="text"
                size="large"
                fullWidth
                onClick={() =>
                  navigate(
                    searchparams.get("redirect")
                      ? `/cadastro?redirect=${searchparams.get("redirect")}`
                      : `/cadastro`
                  )
                }
              >
                Criar uma conta agora
              </Button>
            </Grid>
          </Grid>
        </Container>
      </MainContainer>
    </>
  );
}
