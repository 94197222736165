import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../../services/api";
import { IPaymentMethod, IServiceGroup } from "../../types";
import { createAppointmentWebSetupKey } from "./keys";

export const useAppointmentWebSetup = (
  options?: UseQueryOptions<IAppointmentWebSetup>
) => {
  return useQuery(
    createAppointmentWebSetupKey(),
    () => api.get(`/users-patients/setup`).then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 2 * 60,
      refetchInterval: 1000 * 2 * 60,
      refetchOnWindowFocus: false
    }
  );
};

export interface IAppointmentWebSetup {
  modules: Modules;
  palette: Palette;
  configs: Configs;
}

export interface Modules {
  convenio: boolean;
  combo: boolean;
  pagamentoonline: boolean;
  parcelamento: boolean;
  regrasparcelamento?: IAppointmentWebSetupInstallments[];
}

export interface IAppointmentWebSetupInstallments {
  idparcelamentoagendamentoonline: string;
  idsetup: string;
  setup: IAppointmentWebSetup;
  idgruposervico: string;
  gruposervico: IServiceGroup;
  idmetodopagamento: string;
  metodopagamento: IPaymentMethod;
  regrasparcelamento?: {
    valorminimo: number;
    parcela: number;
  }[];
}

export interface Palette {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  background?: string;
}

export interface Configs {
  home: Home;
  general: General;
  appointment: Appointment;
}

export interface Home {
  title?: string;
  subtitle?: string;
  text_appointment_card?: string;
  text_appointment_button?: string;
  url_background_image?: string;
}

export interface General {
  text_footer?: string;
  url_logo?: string;
  url_site?: string;
}

export interface Appointment {
  text_appointment_card?: string;
  url_image_card?: string;
}
