import {
  Avatar,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// third-party
import { Calendar, SignOut, User } from "phosphor-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Transitions from "../../layout/Extends/Transitions";
import { useAuthenticatedUser } from "../../store/user";
import { stringAvatar } from "../../utils/masks";
import MainCard from "../MainCard";

const ProfileSection: React.FC = () => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { data: user } = useAuthenticatedUser();
  const { signOut, isLoading } = useAuth();

  const handleLogout = async () => {
    await signOut();
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event: any, index: number, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef.current) {
        anchorRef.current.focus();
      }
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box
      sx={{
        ml: 3,
        [theme.breakpoints.down("md")]: {
          ml: 2,
        },
      }}
    >
      {user !== undefined ? (
        // <IconButton
        //   size="medium"
        //   ref={anchorRef}
        //   onClick={handleToggle}
        //   color="primary"
        // >
        //   <Avatar
        //     {...stringAvatar(user.paciente.nomepaciente, 32, 32)}
        //     sx={{
        //       cursor: "pointer",
        //     }}
        //     aria-controls={open ? "menu-list-grow" : undefined}
        //     aria-haspopup="true"
        //     color="inherit"
        //     variant="circular"
        //   >
        //     <User weight="bold" />
        //   </Avatar>
        // </IconButton>
        <Chip
          sx={{
            height: "48px",
            alignItems: "center",
            borderRadius: "27px",
            transition: "all .2s ease-in-out",
            borderColor: theme.palette.background.default,
            backgroundColor: theme.palette.background.default,
            '&[aria-controls="menu-list-grow"], &:hover': {
              borderColor: theme.palette.primary.main,
              background: `${theme.palette.primary.main}!important`,
              color: theme.palette.background.default,
              "& svg": {
                fill: theme.palette.background.default,
              },
            },
            "& .MuiChip-label": {
              lineHeight: 0,
            },
          }}
          icon={
            <Avatar
              {...stringAvatar(
                user !== undefined ? user.paciente.nomepaciente : "Usuário",
                34,
                34
              )}
              sx={{
                fontSize: "1.2rem",
                margin: "8px 0 8px 8px !important",
                cursor: "pointer",
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              color="inherit"
            />
          }
          label={
            <Typography
              sx={{
                color: "inherit",
              }}
            >
              Área do paciente
            </Typography>
          }
          variant="outlined"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="primary"
        />
      ) : (
        <Tooltip title="Entre na sua conta">
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            fullWidth
            href="/login"
            disableElevation
          >
            Área do cliente
          </Button>
        </Tooltip>
      )}
      {user && (
        <Popper
          placement="bottom-start"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          sx={{
            zIndex: 1000,
          }}
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 14],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    border={false}
                    elevation={16}
                    //@ts-ignore
                    content={false}
                    sx={{
                      minHeight: 100,
                      height: 300,
                    }}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 2 }}>
                      <Stack>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography>Olá,</Typography>
                          <Typography sx={{ fontWeight: 600 }}>
                            {user.paciente.nomepaciente}
                          </Typography>
                        </Stack>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: theme.palette.text.disabled,
                            fontWeight: 400,
                          }}
                        >
                          Paciente
                        </Typography>
                      </Stack>
                    </Box>
                    <PerfectScrollbar
                      style={{
                        overflowX: "hidden",
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <Divider />
                        <List
                          component="nav"
                          sx={{
                            width: "100%",
                            maxWidth: 350,
                            minWidth: 200,
                            backgroundColor: theme.palette.background.default,
                            borderRadius: "10px",
                            [theme.breakpoints.down("md")]: {
                              minWidth: "100%",
                            },
                          }}
                        >
                          <ListItemButton
                            selected={selectedIndex === 1}
                            onClick={(event) =>
                              handleListItemClick(
                                event,
                                1,
                                "/paciente/dashboard"
                              )
                            }
                          >
                            <ListItemIcon>
                              <Calendar />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body1">
                                  Área do paciente
                                </Typography>
                              }
                            />
                          </ListItemButton>
                          <ListItemButton
                            selected={selectedIndex === 2}
                            onClick={(event) =>
                              handleListItemClick(
                                event,
                                2,
                                "/paciente/dashboard/minha-conta"
                              )
                            }
                          >
                            <ListItemIcon>
                              <User />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body1">
                                  Minha conta
                                </Typography>
                              }
                            />
                          </ListItemButton>
                          <ListItemButton
                            selected={selectedIndex === 3}
                            disabled={isLoading}
                            onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <SignOut />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body1">Sair</Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Box>
                    </PerfectScrollbar>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            </Transitions>
          )}
        </Popper>
      )}
    </Box>
  );
};

export default ProfileSection;
