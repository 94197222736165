import { QueryKey } from "react-query";

export const createUseAppointmentKey = ({
  patientId,
  clinicId,
  attended
}: {
  patientId: string;
  clinicId?: string;
  attended?: boolean;
}): QueryKey => ["useAppointment", patientId, clinicId, attended];

export const createUseAppointmentByResponsibleIdKey = ({
  patientId,
}: {
  patientId: string;
}): QueryKey => ["useAppointmentByResponsibleId", patientId];

export const createUseAppointmentByIdKey = ({
  appointmentId,
}: {
  appointmentId: string;
}): QueryKey => ["useAppointmentById", appointmentId];

export const createUseServiceOrderIdKey = ({
  serviceOrderId,
}: {
  serviceOrderId: string;
}): QueryKey => ["useServiceOrderIdKey", serviceOrderId];
